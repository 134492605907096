import './App.scss';
import {useContext, useEffect, useRef} from "react";
import AppContext from "./AppContext";
import API from "./API";
import {BrowserRouter as Router, Navigate, Route, Routes as Switch} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PasswordReset from "./pages/PasswordReset";
import Logout from "./pages/Logout";
import Loading from "./components/Loading";
import {SnackbarProvider} from "notistack";
import {ThemeProvider} from "react-bootstrap";
import Dashboard from "./pages/Dashboard";
import Leads from "./pages/Leads";
import Balance from "./pages/Balance";
import FAQ from "./pages/FAQ";
import Settings from "./pages/Settings";
import Transactions from "./pages/Transactions";

function App() {
  const app = useContext(AppContext);
  const appRef = useRef({});

  useEffect(() => {
    appRef.current = app;
  }, [app])

  API.interceptors.request.use( (config) => {
    //setLoading(true)
    if(config?.url !== '/ping')
      document.getElementById('app-loading').className = 'visible';
    return config;
  }, (error) => {
    document.getElementById('app-loading').className = '';
    //setLoading(false)
    return Promise.reject(error);
  });
  API.interceptors.response.use((response) => {
    document.getElementById('app-loading').className = '';
    //setLoading(false)
    return response;
  },  (error) => {
    document.getElementById('app-loading').className = '';
    //setLoading(false)
    return Promise.reject(error);
  });

  return (
      <Router>
        <SnackbarProvider autoHideDuration={3000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <ThemeProvider>
            <Switch>
              <Route path="/login" exact={true} element={<Login />} />
              <Route path="/signup" exact={true} element={<Signup />} />
              <Route path="/password-reset" exact={true} element={<PasswordReset />} />
              <Route path="/logout" exact={true} element={<Logout />} />
              <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
              <Route path="/faq" element={<PrivateRoute><FAQ /></PrivateRoute>} />
              <Route path="/balance" element={<PrivateRoute><Balance /></PrivateRoute>} />
              <Route path="/leads" element={<PrivateRoute><Leads /></PrivateRoute>} />
              <Route path="/transactions" element={<PrivateRoute><Transactions /></PrivateRoute>} />
              <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="*" element={ <Navigate to="/" />} />
            </Switch>
          </ThemeProvider>
        </SnackbarProvider>
        <Loading show={false} /> {/* app.loading */}
      </Router>
  );
}

export default App;
