import React, { useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, ArcElement, RadialLinearScale,
} from 'chart.js';
import {Bar, Pie, PolarArea} from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    ChartDataLabels,
    ArcElement,
    RadialLinearScale,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const BarChart = ({ chartData }) => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                },
            },
            y: {
                ticks: {
                    stepSize: 1
                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
        },
    };

    const labels = chartData.labels;

    const data = {
        labels,
        datasets: [
            {
                label: 'Leads',
                data: chartData.values,
                backgroundColor: 'rgb(87,58,238)',
                borderRadius: 10
            },
        ],
    };

    return (
        <div style={{height: 200}}>
            <Bar data={data} options={options}  type={"bar"} />
        </div>
    );
};
export const PieChart = ({ chartData }) => {
    const options = {
        layout: {
            padding: 20,
            margin: 0
        },
        responsive: true,
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += parseInt(data);
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    return percentage;
                },
                color: '#fff',
                font: {
                    weight: 'bold',
                    size: 20
                },
                textShadowBlur: 10,
                textShadowColor: 'rgba(0, 0, 0, 0.3)'
            },
            legend: {
                position: 'right',
                display: true
            },
        },
    };

    const labels = chartData.labels;

    const data = {
        labels,
        datasets: [
            {
                label: 'Leads',
                data: chartData.values,
                backgroundColor: [
                    'rgb(249,213,163)',
                    'rgb(87,58,238)',
                    //'rgb(249,163,163)'
                ],
                borderWidth: 0,
                hoverOffset: 20
            },
        ],
    };

    return (
        <div style={{height: 400, marginTop: -80}}>
            <Pie data={data} options={options}  type={"pie"}  />
        </div>
    );
};