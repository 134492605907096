import React, {useState} from 'react';
import API, {getMe, HandleError} from "./API";
import {User} from "./models/User";

const DEFAULT_STATE = {
    token: '',
    user: null,
    loading: false,
    ui: {
        pageTitle: '',
        isDrawerOpen: false,
        darkMode: false,
        //server-side
        new_version: '',
    }
};

const AppContext = React.createContext({...DEFAULT_STATE});

export const AppDataProvider = ({ children }) => {

    let token = window.localStorage.getItem('token');
    if(token?.length <= 10)
        token = '';

    const _ui = {...DEFAULT_STATE.ui }
    const [data, setData] = useState({...DEFAULT_STATE, ui: {..._ui}, token: token});

    const login = (token, user, ui) => {
        setData({...data, token: token, user: user, ui: {...data.ui, ...ui} });
        window.localStorage.setItem('token', token);
    }

    const startLoading = () => {
        setData({...data, loading: true })
    }
    const stopLoading = () => {
        setData({...data, loading: false})
    }

    const hasToken = () => {
        return data.token?.length > 10;
    }

    const isLoggedIn = () => {
        return data.user !== null && data.user?.ID > 0 && data.token?.length > 10;
    }

    const clearLogin = () => {
        window.localStorage.setItem('token', '');
        setData({...data, token: '', user: null });
    }

    const logout = () => {
        clearLogin()
        window.location.href = '/signup';
    }

    const openDrawer = () => {
        let ui = {...data.ui}
        ui['isDrawerOpen'] = true;
        setData({...data, ui: ui })
    }
    const closeDrawer = () => {
        let ui = {...data.ui}
        ui['isDrawerOpen'] = false;
        setData({...data, ui: ui })
    }

    const toggleDrawer = () => {
        let ui = {...data.ui}
        ui['isDrawerOpen'] = !data.ui.isDrawerOpen;
        setData({...data, ui: ui })
    }

    const setUser = (user) => {
        setData({...data, user: user })
    }

    const setUI = (ui) => {
        setData({...data, ui: {...data.ui, ...ui} })
    }

    const refreshMe = async (enqueueSnackbar) => {
        try {
            const resMe = await getMe(data.token)
            if (resMe.status < 200 || resMe.status > 299)
                throw resMe

            login(data.token, new User(resMe.user), resMe.ui)
            return true
        }
        catch(e) {
            HandleError(e, 'Error', enqueueSnackbar, logout);
            return false
        }
    }

    const set = (newData) => {
        setData({...data, ...newData})
    }

    return (
        <AppContext.Provider value={{...data, set, login, isLoggedIn, logout, hasToken, openDrawer, closeDrawer, toggleDrawer,
            refreshMe, setUser, startLoading, stopLoading, setUI, clearLogin}}>
            {children}
        </AppContext.Provider>
    )
};

export default AppContext;