import './Dashboard.scss'
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {Col, Container, ProgressBar, Row} from "react-bootstrap";
import {useContext} from "react";
import AppContext from "../AppContext";
import {useNavigate} from "react-router-dom";
import { BarChart, PieChart } from "../components/Chart";
import Wallet from "../components/Wallet";

export default function Dashboard() {
    const app = useContext(AppContext)
    let available = app.user?.balance.available / app.user?.price;
    let minimum = app.user?.min_payout;

    return <div id="page-dashboard" className={"page " + (app.ui.isDrawerOpen ? 'menu-shown' : '')}>
        <Header />
        <Container className="page-container">
            <Sidebar />
            <div className="page-inner">
                <Container>
                    <Row>
                        <Col sm={8}>
                            <h5><strong>Jouw persoonlijke affiliate link</strong></h5>
                            <p>
                                Deel deze link zodat elke aanmelding terug te vinden is op jouw account.
                                <br />
                                <br />
                                <strong>Jouw Link:</strong> &nbsp;
                                <span className="text-info">https://tturl.nl/{app.user?.username}</span>
                            </p>
                            <h5 className="mt-5"><strong>Status tot uitbetaling</strong></h5>
                            <p>
                                {available >= minimum ? 'Goed gedaan, je kunt nu een opnameverzoek indienen!' : ''}
                                {available >= minimum - 2 && available < minimum ? `Je bent er bijna! Nog slechts ${minimum - available} leads te gaan!` : ''}
                                {available < minimum - 2 ? `Je hebt minimaal ${minimum} leads nodig om een opnameverzoek te kunnen indienen.` : ''}

                            </p>
                            <div style={{maxWidth: 340}}>
                                <ProgressBar now={app.user?.balance.available} max={50} label={`€${app.user?.balance.available}`} variant="success" />
                                <div className="text-right small">Uitbetaling bij &euro;{minimum * app.user?.price}</div>
                            </div>
                            <div className="chart-header mt-5">
                                <h5><strong>Leads</strong></h5>
                                <div>
                                    <small>Vandaag</small>
                                    <strong className="h4 fw-bold">{app.user?.lead_counts?.today}</strong>
                                </div>
                                <div>
                                    <small>Laatste 30 dagen</small>
                                    <strong className="h4 fw-bold">{app.user?.lead_counts?.last_30}</strong>
                                </div>
                            </div>
                            <BarChart chartData={app.user?.graphs.bar} />
                            <div className="chart-header mt-5">
                                <h5><strong>Activiteit laatste 30 dagen</strong></h5>
                            </div>
                            <PieChart chartData={app.user?.graphs.pie} />
                        </Col>
                        <Col sm={4}>
                            <Wallet slim={true} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Container>
    </div>
}