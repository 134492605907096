import React, {useContext, useMemo, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import AppContext from "../AppContext";
import logo from '../assets/img/logo.webp'

export const PrivateRoute = ({ children }) => {
    const app = useContext(AppContext);
    const { pathname } = useLocation();
    const [loggedIn, setLoggedIn] = useState(1) //-1

    useMemo(() => {
        const getMe = async () => {
            setLoggedIn(-1)
            const me = await app.refreshMe()
            if(me) {
                setLoggedIn(1)
            }
            else
                setLoggedIn(0)
        }

        getMe()
    }, [pathname])

    if(loggedIn === -1) {
        return <div className="page-loading-overlay">
            <div><img src={logo} alt="" /></div>
        </div>
    }

    if (loggedIn) {
        return children
    }

    return <Navigate to="/signup" />
}
export default PrivateRoute