import {useContext, useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import './Login.scss'
import API, {HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import {Link, Navigate} from "react-router-dom";
import LoginHeader from "../components/LoginHeader";
import LoginFooter from "../components/LoginFooter";

export default function Signup() {
    const { enqueueSnackbar } = useSnackbar()
    const app = useContext(AppContext)
    const [form, setForm] = useState({
        values: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            username: ''
        },
        errorFields: {},
        error: false,
        loading: false,
    })

    const [redirect, setRedirect] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!form.values.email || !form.values.password || !form.values.first_name || !form.values.last_name || !form.values.username) {
            enqueueSnackbar('Alle velden zijn verplicht.', {variant: 'error', style: {whiteSpace: 'pre-line'}});
            return;
        }

        setForm({...form, loading: true })
        try {
            const resMe = await API({
                method: 'POST',
                url: `/signup`,
                data: {...form.values}
            })

            //console.log(resMe)

            if (resMe.status < 200 || resMe.status > 299)
                throw resMe.data;

            setForm({...form, loading: false})
            app.login(resMe.data.token, resMe.data.user, {...app.ui, ...resMe.data.ui})
            //console.log(resMe.data.redirect)
            setRedirect(resMe.data.redirect ? resMe.data.redirect : '/')
        }
        catch(e) {
            setForm({...form, loading: false})
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
        }
    }

    const onChange = (e) => {
        let values = {...form.values}
        values[e.target.name] = e.target.value
        setForm({...form, values: values})
    }

    return (redirect ? <Navigate to={redirect} /> : <div id="page-login">
        <LoginHeader />
        <Container>
            <div style={{maxWidth: 800, margin: '0 auto', textAlign: 'center'}}>
                <h2 className="mb-4 fw-bold mt-5">TurboLeads – Geld verdienen vanuit huis was nog nooit zo makkelijk!</h2>
                <p className="text-muted">
                    Het Turbo Affiliate Programma helpt iedereen om wat extra geld te verdienen, gewoon vanuit huis! Met een paar simpele klikken heb je alles geregeld.
                    <br />
                    Zo makkelijk is het! Meld je nu aan en begin direct met geld verdienen!
                </p>
            </div>
            <Row>
                <Col sm={6}>
                    <div className="login-form-box signup-form-box">
                        <div className="form-inner">
                            <div className="form-login">
                                <form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3 form-floating" controlId="first_name">
                                        <Form.Control type="text" placeholder="Naam" name="first_name" value={form.values.first_name} onChange={onChange} />
                                        <Form.Label>Naam</Form.Label>
                                        {'first_name' in form.errorFields ? <Form.Text className="text-muted">
                                            {form.errorFields['first_name']}
                                        </Form.Text> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-5 form-floating" controlId="last_name">
                                        <Form.Control type="text" placeholder="Achternaam" name="last_name" value={form.values.last_name} onChange={onChange} />
                                        <Form.Label>Achternaam</Form.Label>
                                        {'last_name' in form.errorFields ? <Form.Text className="text-muted">
                                            {form.errorFields['last_name']}
                                        </Form.Text> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3 form-floating" controlId="email">
                                        <Form.Control type="email" placeholder="E-Mail" name="email" value={form.values.email} onChange={onChange} />
                                        <Form.Label>E-Mail</Form.Label>
                                        {'email' in form.errorFields ? <Form.Text className="text-muted">
                                            {form.errorFields['email']}
                                        </Form.Text> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-5 form-floating" controlId="password">
                                        <Form.Control type="password" placeholder="Wachtwoord" name="password" value={form.values.password} onChange={onChange} />
                                        <Form.Label>Wachtwoord</Form.Label>
                                        {'password' in form.errorFields ? <Form.Text className="text-muted">
                                            {form.errorFields['password']}
                                        </Form.Text> : ''}
                                    </Form.Group>
                                    <p className="small">
                                        Dit wordt je persoonlijke link om leads te genereren, bijv. tturl.nl/yourname.
                                        <br />
                                        <strong>Let op:</strong> De alias kan niet worden aangepast.
                                    </p>
                                    <Form.Group className="mb-3 form-floating" controlId="username">
                                        <Form.Control type="text" placeholder="Alias" name="username" value={form.values.username} onChange={onChange} />
                                        <Form.Label>Alias</Form.Label>
                                        {'username' in form.errorFields ? <Form.Text className="text-muted">
                                            {form.errorFields['username']}
                                        </Form.Text> : ''}
                                    </Form.Group>
                                    <Button variant="primary" size="lg" type="submit">Aanmelden</Button>
                                    <div className="login-options mt-4">
                                        <p>
                                            Heb je al een account?
                                            <br />
                                            <Link to="/login"><strong>Inloggen</strong></Link>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={6}>
                    <h2 className="fw-bolder mb-5 mt-4">Meest gestelde vragen</h2>
                    <Row>
                        <Col sm={12}>
                            <h5>Kan iedereen deelnemen aan dit programma?</h5>
                            <p>
                                Ja, iedereen kan zich registreren. Er zijn geen voorwaarden om mee te doen.
                            </p>
                        </Col>
                        <Col sm={12} className="mt-4">
                            <h5>Hoe verdien ik geld?</h5>
                            <p>
                                Voor elke succesvolle lead ontvang je €10,- en de student krijgt €10,- korting bij hun inschrijving.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="mt-4">
                            <h5>Hoe ontvang ik mijn betaling?</h5>
                            <p>
                                Als je minstens €50,- hebt verdiend (5 goedgekeurde leads), kun je het beschikbare saldo laten overmaken naar je bankrekening.
                            </p>
                        </Col>
                        <Col sm={12} className="mt-4">
                            <h5>Zijn er beperkingen?</h5>
                            <p>
                                Nee, er zijn geen beperkingen.
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <LoginFooter className="mt-5" />
        </Container>
    </div>)
}