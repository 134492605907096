import {useContext, useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import logo from '../assets/img/logo.webp'
import './Login.scss'
import API, {getMe, HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import {Link, Navigate, useSearchParams} from "react-router-dom";
import LoginHeader from "../components/LoginHeader";
import LoginFooter from "../components/LoginFooter";

export default function PasswordReset() {
    const { enqueueSnackbar } = useSnackbar()
    const app = useContext(AppContext)
    const [queryParams, setQueryParams] = useSearchParams();
    const [verified, setVerified] = useState(false);

    const [form, setForm] = useState({
        values: {
            email: queryParams.get('e') ?? '',
            code: queryParams.get('c') ?? '',
            new_password: '',
            trigger: queryParams.get('c')?.length === 8 ? (verified ? 'update' : 'verify') : 'request'
        },
        errorFields: {},
        error: false,
        loading: false,
    })

    const [redirect, setRedirect] = useState('')

    useEffect(() => {
        if(form.values.trigger === 'verify') {
            const verifyCode = async () => {
                setForm({...form, loading: true })
                try {
                    const res = await API({
                        method: 'POST',
                        url: `/password-reset`,
                        data: {...form.values}
                    })

                    if (res.status < 200 || res.status > 299)
                        throw res.data;

                    setForm({...form, loading: false, values: {...form.values, trigger: 'update'}})
                    setVerified(true)
                }
                catch(e) {
                    setForm({...form, loading: false, values: {...form.values, trigger: 'request'}})
                    HandleError(e, 'Error', enqueueSnackbar);
                }
            }
            verifyCode()
        }
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!form.values.email || !form.values.trigger)
            return;

        setForm({...form, loading: true })
        try {
            const res = await API({
                method: 'POST',
                url: `/password-reset`,
                data: {...form.values}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            if(form.values.trigger === 'update') {
                setForm({...form, loading: false})
                enqueueSnackbar(res.data.message, {variant: 'success', style: {whiteSpace: 'pre-line'}});
                setRedirect('/login')
            }
            else {
                setForm({...form, values: {...form.values, trigger: 'requested' }, loading: false})
            }
        }
        catch(e) {
            setForm({...form, loading: false})
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
        }
    }

    const onChange = (e) => {
        let values = {...form.values}
        values[e.target.name] = e.target.value
        setForm({...form, values: values})
    }

    const loginOptions = (<div className="login-options mt-4">
            <p>
                Heb je al een account?
                <br />
                <Link to="/login">Inloggen</Link>
            </p>
            <p>
                Heb je nog geen account?
                <br />
                <Link to="/signup">Aanmelden</Link>
            </p>
        </div>)
    return (redirect ? <Navigate to={redirect} /> : <div id="page-login">
        <LoginHeader />
        <div className="login-form-box">
            <div className="form-inner">
                <div className="form-login">
                    <h2 className="mb-4">Wachtwoord vergeten?</h2>
                    {form.values.trigger === 'requested' && <p>Je hebt zojuist een e-mail ontvangen om je wachtwoord te wijzigen.</p>}
                    {form.values.trigger === 'request' && <form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 form-floating" controlId="email">
                            <Form.Control type="email" placeholder="E-Mail" name="email" value={form.values.email} onChange={onChange} />
                            <Form.Label>E-Mail</Form.Label>
                            {'email' in form.errorFields ? <Form.Text className="text-muted">
                                {form.errorFields['email']}
                            </Form.Text> : ''}
                        </Form.Group>
                        <Button variant="primary" size="lg" type="submit">Nieuw wachtwoord aanvragen</Button>
                        {loginOptions}
                    </form>}
                    {form.values.trigger === 'update' && <form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 form-floating" controlId="email">
                            <Form.Control type="password" placeholder="Nieuw wachtwoord" name="new_password" value={form.values.new_password} onChange={onChange} />
                            <Form.Label>Nieuw wachtwoord</Form.Label>
                            {'new_password' in form.errorFields ? <Form.Text className="text-muted">
                                {form.errorFields['new_password']}
                            </Form.Text> : ''}
                        </Form.Group>
                        <Button variant="primary" size="lg" type="submit">Wachtwoord wijzigen</Button>
                        {loginOptions}
                    </form>}
                </div>
            </div>
            <LoginFooter />
        </div>
    </div>)
}