import {FaCheckToSlot, FaCirclePause, FaWallet} from "react-icons/fa6";
import {Button, Modal, OverlayTrigger, Popover} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useContext, useState} from "react";
import AppContext from "../AppContext";
import './Wallet.scss';
import {FaInfoCircle} from "react-icons/fa";
import API, {HandleError} from "../API";
import {Lead} from "../models/Lead";
import {enqueueSnackbar} from "notistack";

export default function Wallet({ slim }) {
    const app = useContext(AppContext)
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false)

    const openWithdrawModal = () => {
        if(app.user?.balance?.available < app.user?.min_payout * app.user?.price)
            return;

        setShowWithdrawModal(true)
    }
    const closeWithdrawModal = () => {
        setShowWithdrawModal(false)
        setShowConfirm(false)
    }

    const openConfirm = () => {
        setShowConfirm(true)
        setShowWithdrawModal(false)
    }

    const closeConfirm = () => {
        setShowConfirm(false)
        setShowWithdrawModal(true)
    }

    const withdraw = async () => {
        try {
            const res = await API({
                method: 'POST',
                url: `/claim`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            const me = await app.refreshMe()
            setShowConfirm(false)
            enqueueSnackbar(`We've received your request.`, {variant: 'success', style: {whiteSpace: 'pre-line'}});
        }
        catch(e) {
            setShowConfirm(false)
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
        }
    }
    const popoverOne = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">On Hold</Popover.Header>
            <Popover.Body>
                Dit zijn mogelijke verdiensten die beschikbaar worden zodra de leads zijn "goedgekeurd".
            </Popover.Body>
        </Popover>
    )
    return <>
        <div className={"wallet" + (slim ? " wallet-slim" : "")}>
            <h4 className="mb-4">Digitale Portemonnee</h4>
            <div className="wallet-items">
                <div className="wallet-item">
                    <FaWallet />
                    <div>
                        <span>Totaal</span>
                        <strong>&euro;{app.user?.balance?.pending + app.user?.balance?.available}</strong>
                    </div>
                </div>
                <div className="wallet-item">
                    <FaCirclePause />
                    <div>
                        <span>In behandeling <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popoverOne}>
                          <small><FaInfoCircle color="black" style={{fontSize: 14}} /></small>
                        </OverlayTrigger>
                        </span>
                        <strong>&euro;{app.user?.balance?.pending}</strong>
                    </div>
                </div>
                <div className="wallet-item">
                    <FaCheckToSlot />
                    <div>
                        <span>Beschikbaar</span>
                        <strong>&euro;{app.user?.balance?.available}</strong>
                    </div>
                </div>
                <div className="wallet-item">
                    <div style={{width: '100%'}}>
                        <Button variant="white" onClick={openWithdrawModal} disabled={app.user?.balance?.available < app.user?.min_payout * app.user?.price}>Opnemen</Button>
                        <div className="text-center small">
                            Minimumbedrag &euro;{app.user?.min_payout * app.user?.price}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal show={showWithdrawModal} onHide={closeWithdrawModal}>
            <Modal.Header closeButton>
                <Modal.Title>Geld opnemen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Wilt u het beschikbare saldo opnemen?
                </p>
                <p>
                    <div className="text-muted">Beschikbaar saldo:</div>
                    <div className="h4">&euro;{app.user?.balance?.available}</div>
                    <div className="text-muted mt-4">Bankrekeninggegevens:</div>
                    <div className="strong mb-4 mt-2">
                        IBAN: {app.user?.iban}
                        <br />
                        SWIFT: {app.user?.swift}
                        <br />
                        Account Holder: {app.user?.first_name} {app.user?.last_name}
                    </div>
                    <Link to="/settings" className="small">
                        Bankrekeninggegevens wijzigen
                    </Link>
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={closeWithdrawModal}>
                    Annuleren
                </Button>
                <Button variant="primary" onClick={openConfirm}>
                    Opnemen
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showConfirm} onHide={closeConfirm}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Proceed with withdraw request?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={closeConfirm}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={withdraw}>
                    Proceed
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}