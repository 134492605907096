import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {Container} from "react-bootstrap";
import {useContext} from "react";
import AppContext from "../AppContext";
import "./Balance.scss";
import {Link} from "react-router-dom";
import Wallet from "../components/Wallet";

export default function Balance() {
    const app = useContext(AppContext)

    return <div id="page-balance" className={"page " + (app.ui.isDrawerOpen ? 'menu-shown' : '')}>
        <Header />
        <Container className="page-container">
            <Sidebar />
            <div className="page-inner">
                <Wallet />
                <div className="mt-5">
                    <h5>Saldo</h5>
                    <p>
                        Het minimumbedrag om op te nemen is €{app.user?.min_payout * app.user?.price}.
                        <br />
                        Je moet minimaal €{app.user?.min_payout * app.user?.price} bereiken om een opname te kunnen doen.
                        <br />
                        <br />
                        Het bedrag wordt binnen 5 werkdagen naar je <Link to="/settings">bankrekening</Link> overgemaakt.
                    </p>
                </div>
            </div>
        </Container>
    </div>
}