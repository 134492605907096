import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {Badge, Container, Table} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AppContext from "../AppContext";
import "./Transactions.scss";
import {Claim} from "../models/Claim";
import {useSnackbar} from "notistack";
import API, {HandleError} from "../API";
import {claim_status_badge, fdate} from "../Common";
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem'

export default function Transactions() {

    const app = useContext(AppContext)
    const [table, setTable] = useState({
        items: [],
        page: 1,
        pages: 1,
        itemCount: 0,
        perPage: 0
    })
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        getItems()
    }, [])

    const getItems = async (page) => {
        try {
            const res = await API({
                method: 'GET',
                url: `/claims?page=${page ?? 1}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            let items = res.data.items.map((jsonData, idx) => {
                return new Claim(jsonData)
            })
            setTable({...res.data, items: items})
        }
        catch(e) {
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
        }
    }

    let paginationItems = [];
    for (let number = 1; number <= table.pages; number++) {
        paginationItems.push(
            <PageItem key={number} active={number === table.page} onClick={() => getItems(number)}>
                {number}
            </PageItem>,
        );
    }

    return <div id="page-claims" className={"page " + (app.ui.isDrawerOpen ? 'menu-shown' : '')}>
        <Header />
        <Container className="page-container">
            <Sidebar />
            <div className="page-inner">
                <Container>
                    <h5><strong>Laatste transacties</strong></h5>
                    <p>
                        <small>Totaal aantal transacties: {table.itemCount}</small>
                    </p>
                    <Table borderless>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Status</th>
                            <th>Bedrag</th>
                            <th>Datum</th>
                        </tr>
                        </thead>
                        <tbody>
                        {table.items.map((item, idx) => {
                            return <>
                                <tr key={`lead-item-${idx}`}>
                                    <th>{((table.page - 1) * table.perPage) + (idx + 1)}</th>
                                    <td>
                                        {claim_status_badge(item.status)}
                                    </td>
                                    <td>
                                        &euro;{item.amount.toFixed(2)}
                                    </td>
                                    <td>{fdate(item.date_created)}</td>
                                </tr>
                                {item.notes ? <tr className="claim-notes">
                                    <td> </td>
                                    <td colSpan={4} className="text-muted"><span dangerouslySetInnerHTML={{__html: item.notes }}></span></td>
                                </tr> : ''}
                            </>
                        })}
                        {table.items.length < 1 && <tr><td colSpan={4} className="text-muted">Nog geen transacties.</td></tr>}
                        </tbody>
                    </Table>
                    {table.pages > 1 ?
                    <Pagination>
                        <Pagination.First disabled={table.page === 1} onClick={() => getItems(1)} />
                        <Pagination.Prev disabled={table.page === 1} onClick={() => getItems(table.page - 1)} />
                        {paginationItems}
                        <Pagination.Next disabled={table.page >= table.pages} onClick={() => getItems(table.page + 1)}  />
                        <Pagination.Last disabled={table.page >= table.pages} onClick={() => getItems(table.pages)}  />
                    </Pagination> : ''}
                </Container>
            </div>
        </Container>
    </div>
}